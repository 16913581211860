export default [
    {
        job_name: "数据分析岗",
        edu: "硕士",
        place: "南京",
        department: "气象服务部",
        responsibility: `
        1.辅助完成公司科研类项目的相关工作，包括现场调研、国内外相关资料的总结梳理，数据计算与分析等，按计划完成节点任务；<br/>
        2.研究领域主要涉及气象灾害风险管理、能源气象、民航气象、城市洪涝风险控制等；<br/>
        3.辅助与合作团队的沟通及合作项目的相关协调与管理工作。`,
        requirements: `
        1.具有气象相关研究背景，硕士及以上学历，大气科学/气象学/大气物理与大气环境等气象相关专业优先；<br/>
        2.具备基本的python编程能力；<br/>
        3.较强的国内外资料快速检索与总结能力；<br/>
        4.较强的文字编辑与表达能力；<br/>
        5.较强的逻辑思维与主动学习能力。<br/>
        `
    },
    {
        job_name: "人工智能岗",
        edu: "硕士",
        place: "南京",
        department: "气象服务部",
        responsibility: `
         1.独立地搭建深度学习、机器学习网络;<br/>
         2.独立处理雷达卫星等气象遥感图片数据，根据特定的问题选择模型，解决气象分类或回归问题；<br/>
         3.通过github、文献阅读等方法，分析和尝试不同新的模型，适用到气以象上解决问题。<br/>
         `,
        requirements: `
         1.硕士及以上学历，气象相关学科/计算机/自动化相关专业均可。<br/>
         2.具备算法建模能力，掌握机器学习、深度学习等技术，对不同算法模型的适用场景有解，接触过相关项目优先。<br/>
         3.具备编程能力，熟悉python或者R语言，数理基础扎实，有算法工程经验优先。<br/>
         4.具有良好的文献阅读能力和信息检索能力，可以快速调研相关文献进展。发表过论文优先。<br/>
         5.思维清晰，具有良好的理解能力和沟通能力，认真负责。<br/>
         `
    }, {
        job_name: "气象产品经理",
        edu: "硕士",
        place: "南京",
        department: "气象服务部",
        responsibility: `
        1.开展市场及用户需求分析，结合公司目前的产品与服务，进行产品规划，组织产品技术预研工作。<br/>
        2.根据公司决策，对拟进行的新产品研发项目输出新产品系统方案。<br/>
        3.根据需求进行市场调研、行业分析，形成调研报告，并生成项目方案并与客户再次沟通对接。<br/>
        4.根据各部门反馈的质量缺陷问题，及时做好汇总，并初步分析原因，提出改进需求，并做好缺陷跟踪工作。<br/>
        5参与项目申报、专利等知识产权认证申报。<br/>
        6.能够无障碍的沟通协调研发端与用户端。<br/>
        `,
        requirements: `
        1.本科及以上学历，气象、大气、遥感、软件工程等相关专业；<br/>
        2.对气象产品和气象业务需求有一定的了解，有气象或环保行业产品经理工作经验优先;<br/>
        3.掌握行业及市场调研、分析方法，掌握技术调研方法和技术预研方法;<br/>
        4.具备良好的沟通对接能力，学习能力较强。<br/>
        `
    }, {
        job_name: "前端开发工程师",
        edu: "本科",
        place: "南京",
        department: "信息化研发部",
        responsibility: `
        1.与产品、UI和后端开发工程师密切配合，完成web前端的开发工作；<br/>
2.创造性解决产品在实现过程中的技术难题，应用前沿技术提升产品的核心竞争力；<br/>
3.协助项目经理进行工作评估，积累项目优化经验。<br/>
        `,
        requirements: `
        1.热爱计算机编程，丰富的编码实战经验；<br/>
2.熟悉JS/HTML/CSS等前端开发技术；<br/>
3.熟悉Vue/React等主流框架；<br/>
4.熟悉Node.js<br/>
5.对交互体验、可用性、用户体验有一定程度的理解。<br/>
6.本科及以上学历，计算机、通信、软件工程等相关专业。<br/>
        `,
    }, {
        job_name: "JAVA开发工程师",
        edu: "本科",
        place: "南京",
        department: "信息化研发部",
        responsibility: `
        1.编写规范的代码，并完成相应的技术文档；<br/>
2.负责项目各阶段性测试和必要的技术支持，代码质量自我闭环性高；<br/>
3.钻研前沿技术，力争突破；<br/>
4.协助项目经理进行工作评估，积累项目优化经验。<br/>

        `,
        requirements: `
        1.热爱计算机编程，丰富的编码实战经验；<br/>
2.熟悉Java核心，网络，集合，多线程编程，熟悉常用算法与数据结构；<br/>
3.了解Linux系统，掌握数据库SQL语言和编程；<br/>
4.逻辑清晰，能和团队高效沟通；<br/>
5.本科及以上学历，计算机、通信、软件工程等相关专业。<br/>
        `,
    }, {
        job_name: "产品经理",
        edu: "本科",
        place: "南京",
        department: "信息化研发部",
        responsibility: `
        1.负责气象/环境相关项目的产品设计、需求分析、功能设计并推动落地，包含解读招标文件、需求沟通、原型设计、PRD文档撰写等；<br/>
2.负责产品生命周期的跟进，把控需求对接、研发、测试、发布交付节点和质量，控制需求变更；<br/>
3.负责收集与反馈产品的运行情况，推动产品更新迭代；<br/>
4.协助项目经理进行工作评估、资源协调、项目汇报及材料编写等；<br/>
5.负责调研需求痛点、竞品及其他相关资料；<br/>
        `,
        requirements: `
        1.大气、环境、遥感、地信等相关专业，有产品或项目管理经验者优先；<br/>
        2.对气象、环境及其相关行业有较深入理解，了解常见的气象/环境数据类型及格式；<br/>
        3.掌握word、EXCEL、PPT等常用办公软件操作，具备良好的文案编写功底，熟悉气象环保类材料优先；<br/>
        4.熟悉PPT或keynote等办公软件，具备良好的宣讲能力，能够流畅的进行汇报和讲解；<br/>
        5.具备严谨的逻辑分析能力，可承担需求分析、产品规划设计工作，熟悉AXURE、墨刀或其他设计工具，熟悉Xmind、Mindmanager、Visio等软件操作，熟练者优先；<br/>
        6.性格稳重开朗，具备良好的沟通和团队合作能力。<br/>
        `,
    }, {
        job_name: "解决方案经理",
        edu: "硕士",
        place: "南京",
        department: "市场推广部",
        responsibility: `
        1.技术交流与需求沟通，提供解决方案，引导客户业务需求，促使客户形成采购需求；<br/>
2.负责组织产品售前阶段的现场演示、产品解说相关工作；<br/>
3.根据公司发展战略，编写公版方案、公版宣传材料等；<br/>
4.对接行业线方案研发部门进行综合解决方案标准化与再创新；<br/>
5.解决方案的市场推广、售前/销售/客户培训。<br/>

        `,
        requirements: `硕士及以上学历，大气、环境、遥感等相关专业`,
    }, {
        job_name: "销售经理",
        edu: "本科",
        place: "南京",
        department: "市场推广部",
        responsibility: `
        1.负责公司市场开发、产品和技术宣传，努力开拓新市场、开发新客户； <br/>
2.与客户沟通协调，反馈客户需求，协调技术人员与客户开展技术交流； <br/>
3.根据公司营销计划完成销售目标；<br/>
4.项目跟踪，推动项目的招投标、合同签订、项目实施、验收及回款等工作；<br/>

        `,
        requirements: `
        1.大气环境、化学、数学等相关专业优先考虑；<br/>
2.具有与政府部门、环保部门等类型客户交流经验者优先；    <br/>
3.有一定的营销策略和技巧，有公关技巧和经验；    <br/>
4.可适应出差，具备一定抗压能力；    <br/>
5.具备市场敏锐度，有维护渠道及开发客户能力。<br/>
销售区域：<br/>
安徽、山东
        `,
    }, {
        job_name: "招投标主管",
        edu: "本科",
        place: "南京",
        department: "市场推广部",
        responsibility: `
        1、收集招标信息，认真阅读招标文件要求，验证投标资格及标书中涉及到的相应工作； <br/>
        2、根据招标文件要求，收集制作招标文件需要的技术和商务资料；<br/>
        3、按招标文件要求，办理报名及保证金交纳等手续，并负责催返；<br/>
        4、负责投标文件的编制、整体投标文件的排版、打印、复印、装订、包装等工作，按时完成投标文件的密封，并按规定如期完成标书制作；<br/>
        5、与公司相关部门积极协调投标文件编制过程中的问题，确保投标文件按时投递；<br/>
        6、提供投标现场需要的资料；<br/>
        7、与财务对账客户投标保证金回款情况，与品质管理部等其他部门的工作协调和沟通；<br/>
        8、公司招投标相关资质申报，收集和更新公司资质、人员资质；<br/>
        9、完成公司领导临时交办的其他任务。<br/>
        
        `,
        requirements: `
        1、1年以上同岗位经验优先； <br/>
2、具备较强的文字编辑组织能力；<br/>
3、能熟练操作Word、Excel、PPT、思维导图等办公软件； <br/>
4、能独立编写公文函件，工作计划，整改提升等执行方案；<br/>
5、熟悉招投标流程、法规等，具备独立熟练编写标书。 <br/>
        `,
    }, {
        job_name: "走航分析工程师",
        edu: "大专",
        place: "南京",
        department: "装备工程部",
        responsibility: `
        1、负责环境空气在线监测设备（VOCs，多组分气体，雷达等）走航监测工作；<br/>
        2、负责现场现场仪器故障的初步排查与简易处理；<br/>
        3、负责完成走航监测与数据分析报告撰写工作；<br/>
        4、负责与业主定期沟通，汇报监测结果；<br/>
        5、完成上级交代的其他工作。<br/>
        `,
        requirements: `
        1、全日制本科及以上学历，气象、大气科学、大气物理，环境科学/工程等相关专业；<br/>
        2、熟练使用OFFICE；<br/>
        3、具有熟练驾驶技能（C1驾照）优先考虑；<br/>
        4、接受出差，调配，具有一定抗压能力； <br/>
        5、具有良好的沟通协调与表达能力和团队意识，极强的客户服务意识。<br/>
        `
    }, {
        job_name: "VOCs质谱产品经理",
        edu: "本科",
        place: "南京",
        department: "装备工程部",
        responsibility: `
        1、参与VOCs质谱（PTR-MS）的系统集成开发、整机调试，问题查找、硬件改进及验证实验；<br/>
2、负责仪器样机的应用开发实验工作，包括资料收集、方案确定、数据分析等；<br/>
3、仪器开发过程中，申请专利、发表科技文章等相关工作；仪器宣传材料，以及用户手册等材料的撰写；<br/>
4、负责VOCs走航解决方案开发，售前支持（包括样机试用，方案及数据分析撰写，技术交流等）。<br/>

        `,
        requirements: `
        1、本科及以上学历，分析化学、环境化学、环境科学，环境工程，机械，电子等相关专业；<br/>
2、学习能力强，有钻研精神，有团队意识；<br/>
3、有质谱类仪器开发及操作经验优先；<br/>
4、具备大气环境VOCs监测分析经验者优先。<br/>
        `,
    }, {
        job_name: "大气环境监测数据分析工程师",
        edu: "硕士",
        place: "南京",
        department: "咨询服务部",
        responsibility: `
        1.发挥自身所长参与研究院业务及产品开发，负责或配合项目实施；<br/>
2.基于大气环境监测数据进行大气污染成因解析，编写大气污染管控措施建议。在项目进行过程中与客户保持沟通，增加客户粘性；<br/>
3.参与监测类仪器以及监测数据相关项目开发，参与并负责新产品、技术内部立项。<br/>
        `,
        requirements: `
        1.具有相关行业研究经历，工程师及以上职称，或研究生及以上学历，大气科学、环境科学、环境化学等气象、环保相关专业。<br/>
        2.具有大气化学组分监测研究能力，如仪器操作，数据质控，数据统计分析等，可进行独立报告撰写以及应用开发者优先。掌握如下仪器相关知识，具备实操经验者优先：在线GC-MS，高分辨率气溶胶飞行时间质谱仪等。（常规仪器及数据处理经验丰富者，研究院提供相关业务培训）<br/>
        3.具有大气环境集成分析能力，尤其是基于大气光化学污染过程进行分析能力，熟知大气环境空气质量变化特征及关键来源、关键过程，能就污染管控措施提供相应分析以及落地建议。<br/>
        4.熟练掌握数据分析、可视化方法以及软件，包括但不限于Python、Fortran、Matlab、Igor Pro、Methmetica、Origin、NCL、R语言等。<br/>
        5.熟练掌握PPT、EXCEL、WORD、WPS等办公软件。具备优秀的文档能力，使用文字、图示清楚地表达架构意图，能够熟练编写各类技术文档。<br/>
        6.良好的沟通、理解、表达能力，可较好的组织、编写相关汇报材料，并承担汇报工作。<br/>
        7.良好的团队合作意识，较强的工作责任心，能承担一定程度的工作强度。<br/>
        `
    }, {
        job_name: "大气污染治理工程师",
        edu: "专科",
        place: "南京",
        department: "咨询服务部",
        responsibility: `
        1、对大气环境中非常规监测和常规监测，进行数据收集、处理及实时分析，参与现场排查工作，判断污染来源，为污染源巡查、治理提供针对性管控意见；<br/>
2、结合考核目标，对常规分析、现场工作、管控效果进行总结提炼，可独立完成撰写日报、周报、月报等； <br/>
3、负责与客户沟通数据，为工作开展提供数据支撑，对实况、成效情况进行汇报，协调引导客户需求，协助做分析决策；<br/>
4、项目数据的整理记录、有序归档；<br/>
5、配合项目需求，输出其他类型数据，以及完成工作中上级交办的其他事项。<br/>
        `,
        requirements: `
        1、专科以上学历，大气科学类环境科学环境工程等相关专业，1年以上工作经验，优秀应届生也可招聘录取；<br/>
2、有良好的团队合作精神及沟通能力，逻辑能力及语言组织表达能力，善于提炼总结分析；<br/>
3、良好的数据处理及分析能力，熟练运用PPT、Excel、Word等办公软件；<br/>
4、对工作细心、耐心，积极性高且有较强的责任心；<br/>
5、有较强的抗压能力，能适应中长期出差；<br/>
6、有数据分析整理汇经验报者优先，有政府项目对接、合作经验者优先；<br/>
7、有上进心者和对大气科学领域知识渴望学习者优先考虑。<br/>
        `
    }
]



// export default [
//     {
//         job_name: "气象算法师",
//         edu: "本科",
//         place: "南京",
//         department: "气象研究部",
//         responsibility: `1.负责仪器数据采集以及数据分析软件开发；<br/>
//         2.负责新产品的设计开发；<br/>
//         3.负责现有软件的维护升级；<br/>`,
//         requirements: `1、计算机或相关专业应届毕业生优先；<br/>
//         2、拥有良好的编码习惯，掌握面向对象、面向接口设计思想,合理应用设计模式进行软件设计开发；<br/>
//         3、精通JAVA语言，熟练使用SPRINT BOOT开发；参与过成熟的软件开发项目；<br/>
//         4、熟悉至少一种以上数据库(如MySQL、SQLLite、Oracle等)的开发使用，熟练掌握存储过程编写。<br/>`,
//     },
//     {
//         job_name: "大气环境数据应用工程师",
//         edu: "本科",
//         place: "南京",
//         department: "数据集成研究部",
//         responsibility: `大气环境数据应用分析、业务构成、方案编写等。`,
//         requirements: `1.大气、遥感、环境等专业背景，或具有从事大气环境等相关业务经验；<br/>
//         2.掌握基本数据分析技能，熟知大气环境研究主要内容；<br/>
//         3.具有良好的写作能力，沟通技巧以及口头表达能力。<br/>`,
//     },
//     {
//         job_name: "环境模拟工程师",
//         edu: "本科",
//         place: "南京",
//         department: "数据集成研究部",
//         responsibility: `1.负责城市微环境方向业务研发；<br/>
//         2.设计城市大气环境管理中台功能，对接开发；<br/>
//         3.进行城市气候科研；<br/>
//         4.入职后接受专业培训。<br/>`,
//         requirements: `1.大气或流体力学专业；<br/>
//         2.熟练掌握Linux、Fortran、计算CFD软件等编程语言以及分析工具，具有较好数据可视化能力；<br/>
//         3.具有较强技术研发能力；<br/>
//         4.具有较强责任心以及上进心。<br/>`,
//     },
//     {
//         job_name: "环境空气质量咨询师",
//         edu: "不限",
//         place: "南京",
//         department: "数据集成研究部",
//         responsibility: `1.发挥自身所长参与研究院空气质量保障项目，配合项目实施；<br/>
//         2.基于研究院业务数据系统进行日常大气污染管控措施建议。在项目进行过程中与客户保持沟通，增加客户粘性；<br/>
//         3.参与驻地空气质量保障，有新业务内容开发兴趣。<br/>`,
//         requirements: `1.学历不限，具有相关行业经历者优先；<br/>
//         2.具有大气环境数据分析经验，了解基层大气环境溯源手段以及管控流程者优先；<br/>
//         3.了解大气污染防治管控相关工作流程，可参与驻地各项大气服务保障工作者优先；<br/>
//         4.掌握数据分析、可视化方法以及软件；<br/>
//         5.熟练掌握PPT、EXCEL、WORD、WPS等办公软件。具备优秀的文档能力，使用文字、图示清楚地表达架构意图，能够熟练编写各类技术文档；<br/>
//         6.良好的沟通、理解、表达能力，可较好组织、编写相关汇报材料，并承担汇报工作；<br/>
//         7.良好的团队合作意识，较强的工作责任心，接受外派工作。<br/>`,
//     },
//     {
//         job_name: "环境数据分析师（监测）",
//         edu: "硕士",
//         place: "南京",
//         department: "数据集成研究部",
//         responsibility: `1.发挥自身所长参与研究院业务及产品开发，负责或配合项目实施；<br/>
//         2.基于大气环境监测数据进行大气污染成因解析，编写大气污染管控措施建议；<br/>
//         3.在项目进行过程中与客户保持沟通，增加客户粘性；<br/>
//         4.参与监测类仪器以及监测数据相关项目开发，参与并负责新产品、技术内部立项。<br/>`,
//         requirements: `1.具有相关行业研究经历，工程师及以上职称，或研究生及以上学历，大气科学、环境科学、环境化学等气象、环保相关专业；<br/>
//         具有大气化学组分监测研究能力，如仪器操作，数据质控，数据统计分析等，2.可进行独立报告撰写以及应用开发者优先。掌握如下仪器相关知识，具备实操经验者优先：在线GC-MS，高分辨率气溶胶飞行时间质谱仪等（常规仪器及数据处理经验丰富者，公司提供相关业务培训）；<br/>
//         3.具有大气环境集成分析能力，尤其是基于大气光化学污染过程进行分析能力，熟知大气环境空气质量变化特征及关键来源、关键过程，能就污染管控措施提供相应分析以及落地建议；<br/>
//         4.熟练掌握数据分析、可视化方法以及软件，包括但不限于Python、Fortran、Matlab、lgor Pro、Methmetica、Origin、NCL、R语言等<br/>
//         5.熟练掌握PPT、EXCEL、WORD、WPS等办公软件，具备优秀的文档能力，使用文字、图示清楚地表达架构意图，能够熟练编写各类技术文档；<br/>
//         6.良好的沟通、理解、表达能力，可较好的组织、编写相关汇报材料，并承担汇报工作。<br/>`,
//     },




//     {
//         job_name: "人工智能工程师",
//         edu: "本科",
//         place: "南京",
//         department: "算法部",
//         responsibility: `大数据机器学习方向。`,
//         requirements: `1.本科以上学历，相关专业或有机器学习相关经验；<br/>
//         2.熟悉 Theano、Lasagne、Blocks、TensorFlow、Keras、MXNet、PyTorch、Caffe、CNTK、Neon等机器学习框架中的其中一种；<br/>
//         3.熟悉神经网络、机器学习、图像识别等技术；<br/>
//         4.熟悉Python、Java语言。<br/>`,
//     },
//     {
//         job_name: "气象销售经理",
//         edu: "不限",
//         place: "南京",
//         department: "市场推广部",
//         responsibility: `1.负责公司市场开拓，对公司产品和业务宣传、推广；<br/>
//         2.拓展新客户资源、维系老客户；<br/>
//         3.促成项目落单，推动项目的招投标、合同签订、项目验收及回款等工作；<br/>`,
//         requirements: `1.气象学、大气环境、化学、数学等相关专业优先考虑；<br/>
//         2.具有与气象相关政府部门等类型客户交流经验者优先。<br/>`,
//     }, 

//      {
//         job_name: "市场策划经理",
//         edu: "本科",
//         place: "南京",
//         department: "市场推广部",
//         responsibility: `
//         1.负责公司对外宣传推广，包含产品推广和服务品牌推广；<br/>
//         2.公司微信、网站的运营与维护；<br/>
//         3.营销事件、撰写软文、品牌文案、专题内容的策划指导和执行。<br/>
//         `,
//         requirements: `
//         本科及以上学历，市场营销、经济管理、广告学等专业优先考虑。
//         `,
//     },
    




    

    


    
   
// ];
