<template>
  <div class="talents-box" v-if="!isShowDetail">
    <span class="data-length">共计{{ TalentsDetailText.length }}条</span>
    <table>
      <tr
        v-for="(item, index) in TalentsDetailText"
        :key="item.name"
        @click="toShowDetail(index)"
      >
        <td class="job-name">
          <span class="border-box">{{ item.job_name }}</span>
        </td>
        <td width="130">{{ item.edu }}</td>
        <td width="130">{{ item.place }}</td>
        <td class="department">
          <span>{{ item.department }}</span>
        </td>
        <td class="arrow-icon"><i class="iconfont icon-arrowRight" /></td>
      </tr>
    </table>
  </div>
  <talents-detail v-else />
</template>

<script>
import TalentsDetail from "./TalentsDetail.vue";
import { ref } from "vue";
import { useRoute } from "vue-router";
import TalentsDetailText from "./TalentsDetailText";
export default {
  name: "Talents",
  components: {
    TalentsDetail,
  },
  setup() {
    const toShowDetail = (id) => window.open(`/#/Talents?id=${id}`, "_blank");

    const route = useRoute();
    let isShowDetail = ref(route.query.id);
    return { TalentsDetailText, isShowDetail, toShowDetail };
  },
};
</script>

<style lang="scss" scoped>
.talents-box {
  padding: 20px 20px 40px 20px;
  .data-length {
    display: inline-block;
    margin-bottom: 40px;
    font-weight: 700;
  }
  table {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
    width: 100%;
    font-weight: 700;

    tr {
      height: 60px;
      line-height: 60px;
      width: 100%;
      color: #999999;
      text-align: center;
      cursor: pointer;

      & + tr {
        border-top: 1px solid #dbdbdb;
      }

      &:hover {
        background-color: #f1f1f1;
        .job-name {
          color: #167eb5;
          .border-box {
            position: relative;
            &::before {
              content: "";
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              height: 1px;
              background-color: #167eb5;
            }
          }
        }
        .arrow-icon {
          color: #167eb5;
        }
      }
      .job-name {
        width: 280px;
        text-align: left;
        font-size: 20px;
        color: #222222;
        font-weight: normal;
        text-indent: 0.3em;
      }
      .arrow-icon {
        text-align: right;
        font-size: 25px;
      }
      .department {
        display: flex;
        justify-content: center;
        span {
          width: 120px;
          display: inline-block;
          text-align: left;
        }
      }
    }
  }
}
</style>
